import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";

// styled components
const CustomButton = styled(Button)`
  height: 2.5rem;
  min-width: 12rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  margin-right: ${(props) => (props.buttonType !== null ? "0px" : "1rem")};
  margin-left: ${(props) => (props.buttonType !== null ? "2rem" : "0px")};
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  border-color: ${(props) =>
    !props.isDisabled &&
    props.buttonType === "submit" &&
    "rgba(0, 0, 0, 0.12)"};
`;

/**
 * Blue Button Component, wraps MUI's Button
 *
 * @param {object} props
 * @param props.color
 * @param props.variant
 * @param props.children
 * @param props.style
 * @param props.onClick
 * @param props.isDisabled
 * @param props.buttonType
 * @returns {React.Element}
 */

const BlueButton = ({
  color,
  variant,
  children,
  style,
  onClick,
  isDisabled,
  buttonType,
}) => (
  <CustomButton
    color={color}
    variant={variant}
    style={style}
    onClick={onClick}
    disabled={isDisabled}
    buttonType={buttonType}
  >
    {children}
  </CustomButton>
);

BlueButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  style: PropTypes.shape(),
  buttonType: PropTypes.string,
};

BlueButton.defaultProps = {
  isDisabled: false,
  style: {},
  onClick: null,
  buttonType: null,
};

export default BlueButton;
