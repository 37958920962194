import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import FieldLabel from "./FieldLabel";

// styled components
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10rem;
  grid-row-gap: 0.5em;
`;

const CheckBoxContainer = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

const CustomCheckBox = styled(Checkbox)`
  svg {
    font-size: 1.2rem;
    stroke-width: 0.25px;
    color: #767676;
  }
  &:hover {
    background: transparent;
  }
`;

const CustomControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
    color: #5c5c5c;
    width: fit-content;
    text-align: center;
    margin-top: 0.03rem;
  }
`;

/**
 * Returns a grid layout with checkbox components within it
 *
 * @param {object} props
 * @param props.label
 * @param props.checkboxValues
 * @param props.selectedValues
 * @param props.toggleCheckbox
 * @param props.type
 * @returns {React.Element}
 */

const CheckboxGrid = ({
  label,
  checkboxValues,
  selectedValues,
  toggleCheckbox,
  type,
}) => (
  <FieldLabel
    style={{
      marginLeft: type === "single" ? "-7px" : 0,
    }}
    labelwidth={type === "single" ? "0px" : "6rem"}
    control={
      <GridContainer>
        {checkboxValues.map((item) => (
          <CheckBoxContainer key={item.id}>
            <CustomControlLabel
              label={item.value}
              labelPlacement="end"
              control={
                <CustomCheckBox
                  checked={selectedValues.includes(item.value)}
                  onClick={() => toggleCheckbox(item.value, type)} // inline function needed here as we need to account for dynamic values
                  disableRipple
                />
              }
            />
          </CheckBoxContainer>
        ))}
      </GridContainer>
    }
    label={label}
    labelPlacement="start"
  />
);

CheckboxGrid.propTypes = {
  label: PropTypes.string,
  checkboxValues: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

CheckboxGrid.defaultProps = {
  label: null,
};

export default CheckboxGrid;
