import React, { useCallback } from "react";
import { AppBar, Toolbar, Typography, Box } from "@material-ui/core";
import { useKeycloak } from "@react-keycloak/web";
import styled from "styled-components";
import logo from "../assets/tx-scheduling-logo.png";
import BlueButton from "./BlueButton";
import GreenButton from "./GreenButton";

// styled components
const CustomNavBar = styled(AppBar)`
  background-color: white;
  height: 3.75rem;
`;

const CustomToolbar = styled(Toolbar)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const LogoStyle = styled.img`
  width: 12rem;
  height: 3rem;
  cursor: pointer;
`;

const FlexRow = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const CompanyText = styled(Typography)`
  text-align: center;
  color: black;
  font-size: 0.9rem;
  margin-right: 1rem;
  font-weight: bold;
`;

/**
 * Navbar Header Component
 *
 * @returns {React.Element} An auth-sensitive component that displays the logout button to logged-in users
 */
const HeaderBar = () => {
  const { keycloak } = useKeycloak();

  // logout function
  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  // If already logged in, redirect to source page, if any (defaults to Appointments)

  return (
    <CustomNavBar position="sticky">
      <CustomToolbar>
        <LogoStyle src={logo} />
        <FlexRow>
          {keycloak?.authenticated && (
            <CompanyText>Organization Name</CompanyText>
          )}
          {keycloak?.authenticated && (
            <GreenButton
              color="secondary"
              type="button"
              variant="outlined"
              onClick={logout}
            >
              Log Out
            </GreenButton>
          )}
          <BlueButton color="primary" variant="outlined">
            Contact Support
          </BlueButton>
        </FlexRow>
      </CustomToolbar>
    </CustomNavBar>
  );
};

export default HeaderBar;
