import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../constants/colors";
import { TextField } from "@material-ui/core";
import FieldLabel from "./FieldLabel";

// styled components
const CustomTextField = styled(TextField)`
  background-color: ${colors.white};
  width: 17rem;
  border-radius: 8;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
  .MuiInput-root.Mui-disabled {
    background-color: white;
    color: black;
    border: none;
  }
  .MuiInput-input {
    padding-top: 0.5rem;
  }
`;

/**
 * Text Field Component
 *
 * @param {object} props
 * @param props.labelText
 * @param props.textFieldPlaceholder
 * @param props.setValue
 * @param props.value
 * @returns {React.Element}
 */

const TextInput = ({ labelText, textFieldPlaceholder, setValue, value }) => {
  const handleTextChange = (e) => {
    setValue(e.target.value);
  };

  const getFormWidth = () => {
    if (labelText === null) {
      return "0px";
    }
    return "10rem";
  };
  return (
    <FieldLabel
      control={
        <CustomTextField
          placeholder={textFieldPlaceholder}
          InputProps={{ disableUnderline: true }}
          onChange={handleTextChange}
          value={value}
          disabled={setValue === null}
        />
      }
      label={labelText}
      labelPlacement="start"
      labelwidth={getFormWidth()}
    />
  );
};

TextInput.propTypes = {
  labelText: PropTypes.string,
  textFieldPlaceholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func,
};

TextInput.defaultProps = {
  labelText: null,
  setValue: () => {},
};

export default TextInput;
