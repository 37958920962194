import React from "react";
import styled from "styled-components";
import { Box, Grid } from "@material-ui/core";
import MenuButton from "../../components/MenuButton";

// styled components
const Wrapper = styled(Box)`
  height: calc(100vh - 7.125rem);
  background-color: #2962ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled(Grid)`
  position: relative;
  height: 100%;
  width: 75%;
  margin-top: -7.5em;
  .MuiGrid-grid-xs-true {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

/**
 * Appointment Page Component
 *
 * @returns {React.Element} styled components present within the Appointments page.
 */
const AppointmentsPage = () => (
  <Wrapper>
    <Background container direction="row" alignItems="center">
      <Grid item xs>
        <MenuButton type="New" path="/appointments/new">
          New Appointment
        </MenuButton>
      </Grid>

      <Grid item xs>
        <MenuButton type="Reschedule" path="/appointments/reschedule">
          Reschedule Appointment
        </MenuButton>
      </Grid>

      <Grid item xs>
        <MenuButton type="Cancel" path="/appointments/cancel">
          Cancel Appointment
        </MenuButton>
      </Grid>
    </Background>
  </Wrapper>
);

export default AppointmentsPage;
