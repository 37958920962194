import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import BlueButton from "../../components/BlueButton";
import DropDown from "../../components/DropDown";
import TextInput from "../../components/TextInput";
import BreadCrumbs from "../../components/BreadCrumbs";
import TextArea from "../../components/TextArea";

// styled components
const Wrapper = styled(Grid)`
  background-color: white;
  padding-bottom: 3rem;
  min-height: calc(100vh - 7.125rem);
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
`;

const GridRow = styled(Grid)`
  margin-top: 1.5rem;
`;

const BreadCrumbRow = styled(Grid)`
  margin-top: 1rem;
`;

const GridItem = styled(Grid)`
  direction: row;
  justifycontent: flex-start;
  align-items: center;
`;

const HeaderLarge = styled(Typography)`
  text-align: center;
  color: #5c5c5c;
  font-size: 1.5rem;
`;

const HeaderSmall = styled(Typography)`
  text-align: center;
  color: #333333;
  font-size: 1.1rem;
`;

const DescriptionText = styled(Typography)`
  font-size: 0.75rem;
  color: #5c5c5c;
  text-align: left;
  width: 10rem;
`;

const Divider = styled(Box)`
  postion: relative;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(128, 194, 131, 0.88);
  margin-top: 2rem;
`;

const CenterRow = styled(Grid)`
  position: relative;
  width: fit-content;
  direction: row;
  justifycontent: center;
  align-items: center;
`;

/**
 * The Request Cancel Appointment page and all corresponding UI elements.
 *
 * @private
 *
 * @returns {React.Element}
 *
 */

const CancelAppointmentPage = () => {
  const cancellationReasons = ["Other", "Other2", "Other3", "Other24"];

  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [reasonForCancellation, setReasonForCancellation] = useState("");
  const [notes, setNotes] = useState("");

  const history = useHistory();

  const disregardChanges = () => {
    const path = `/appointments`;
    history.push(path);
  };

  const cancellationReasonChange = (event) => {
    setReasonForCancellation(event.target.value);
  };

  const validateInputs = () => {
    if (reasonForCancellation.length > 0 && confirmationNumber.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <form>
      <Wrapper>
        <BreadCrumbRow container>
          <Grid item xs={12}>
            <BreadCrumbs
              currentPage="Cancel Appointment"
              currentPath="/appointments/cancel"
            />
          </Grid>
        </BreadCrumbRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderLarge> Cancel Appointment Request</HeaderLarge>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Appointment Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Confirmation #"
              textFieldPlaceholder=""
              setValue={setConfirmationNumber}
              value={confirmationNumber}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Cancellation Request</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DropDown
              menuItems={cancellationReasons}
              currentValue={reasonForCancellation}
              type="Cancellation"
              label="Reason for Cancellation"
              onChange={cancellationReasonChange}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DescriptionText>Notes/Special Requests</DescriptionText>
          </GridItem>
        </GridRow>
        <GridRow container style={{ marginTop: "1rem" }}>
          <GridItem item xs={12} container>
            <TextArea
              placeholder="Enter additional notes or special requests."
              value={notes}
              setValue={setNotes}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem
            item
            xs={12}
            container
            direction="row"
            style={{ justifyContent: "space-between" }}
          >
            <div />
            <CenterRow container>
              <BlueButton
                color="primary"
                buttonType="cancel"
                variant="outlined"
                style={{ marginRight: 0 }}
                onClick={disregardChanges}
              >
                Disregard Changes
              </BlueButton>
              <BlueButton
                color="primary"
                variant="contained"
                data-testid="submit-cancellation-request-button"
                buttonType="submit"
                isDisabled={!validateInputs()}
              >
                Submit Cancellation Request
              </BlueButton>
            </CenterRow>
          </GridItem>
        </GridRow>
      </Wrapper>
    </form>
  );
};

export default CancelAppointmentPage;
