import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const CustomButton = styled(Button)`
  & {
    background-color: white;
    height: 2.5rem;
    width: 12rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: none;
    margin-right: 1rem;
    font-weight: bold;
    text-align: center;
    font-size: 1em;
  }
  &:hover {
    background-color: ${colors.green.button};
    color: white;
  }
`;

/**
 * Green Button Component, wraps MUI's Button
 *
 * @param {object} props
 * @param {string} props.color
 * @param {Function} props.onClick
 * @param {string} props.type
 * @param {string} props.variant
 * @param {Array} props.children
 * @returns {React.Element}
 */
const GreenButton = ({ color, onClick, type, variant, children }) => (
  <CustomButton color={color} onClick={onClick} type={type} variant={variant}>
    {children}
  </CustomButton>
);

GreenButton.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.string.isRequired,
};

GreenButton.defaultProps = {
  onClick: () => {},
  type: "button",
};

export default GreenButton;
