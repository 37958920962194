import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import colors from "../constants/colors";
import FieldLabel from "./FieldLabel";

// styled components
const CustomDrop = styled(FormControl)`
  background-color: ${colors.white};
  width: 17rem;
  border-radius: 8px;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
  }
`;

const CustomSelect = styled(Select)`
  font-size: 1rem;
`;

/**
 * Dropdown Component
 *
 * @param {object} props
 * @param props.menuItems
 * @param props.type
 * @param props.currentValue
 * @param props.label
 * @param props.onChange
 * @returns {React.Element}
 */
const DropDown = ({ menuItems, type, currentValue, label, onChange }) => {
  const handleMenuChange = (e) => {
    onChange(e, type);
  };
  return (
    <FieldLabel
      page="appointment"
      control={
        <CustomDrop>
          <CustomSelect
            value={currentValue}
            onChange={handleMenuChange}
            disableUnderline
          >
            {menuItems.map((val) => (
              <MenuItem
                key={`menu${val}`}
                value={val}
                style={{ fontSize: "1rem" }}
              >
                {val}
              </MenuItem>
            ))}
          </CustomSelect>
        </CustomDrop>
      }
      label={label}
      labelPlacement="start"
    />
  );
};

DropDown.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DropDown;
