export const basename = "/customer-portal";

export const login = "/login";
export const appointments = "/appointments";
export const appointment = "/appointments/:appointmentId";
export const appointmentNew = "/appointments/new";
export const appointmentReschedule = "/appointments/reschedule";
export const appointmentEdit = "/appointments/:appointmentId/edit";
export const appointmentCancel = "/appointments/cancel";
export const notFound = "/not-found";
