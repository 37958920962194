import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import TextInput from "../../components/TextInput";
import DatePicker from "../../components/DatePicker";
import TimePicker from "../../components/TimePicker";
import AlternatingTabButton from "../../components/AlternatingTabButton";
import BlueButton from "../../components/BlueButton";
import TextArea from "../../components/TextArea";
import DeleteButton from "../../components/DeleteButton";
import BreadCrumbs from "../../components/BreadCrumbs";

// styled components
const Wrapper = styled(Grid)`
  background-color: white;
  padding-bottom: 3rem;
  min-height: calc(100vh - 7.125rem);
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
`;

const GridRow = styled(Grid)`
  margin-top: 1.5rem;
`;

const BreadCrumbRow = styled(Grid)`
  margin-top: 1rem;
`;

const GridItem = styled(Grid)`
  direction: row;
  justifycontent: flex-start;
  align-items: center;
`;

const CenterRow = styled(Grid)`
  position: relative;
  width: fit-content;
  direction: row;
  justifycontent: center;
  align-items: center;
`;

const HeaderLarge = styled(Typography)`
  text-align: center;
  color: #333333;
  font-size: 1.5rem;
`;

const HeaderSmall = styled(Typography)`
  text-align: center;
  color: #5c5c5c;
  font-size: 1.1rem;
`;

const LargeSpacer = styled(Box)`
  width: 6rem;
  height: 1px;
  background-color: transparent;
`;

const SmallSpacer = styled(Box)`
  width: 3rem;
  height: 1px;
  background-color: transparent;
`;

const Divider = styled(Box)`
  postion: relative;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(128, 194, 131, 0.88);
  margin-top: 2rem;
`;

const DescriptionText = styled(Typography)`
  font-size: 0.75rem;
  color: #5c5c5c;
  text-align: left;
  width: 10rem;
`;

/**
 * The Reschedule Appointment page and all corresponding UI elements.
 *
 * @returns {React.Element}
 *
 */

const RescheduleAppointment = () => {
  const [confirmationNumber, setConfirmationNumber] = useState("");
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [preference, setPreference] = useState("");
  const [alternatingButtonCheck, setAlternatingButtonCheck] = useState(false);

  const history = useHistory();

  // useEffect fired only if the dayPeriod state changes - this is to add or subtract 12 hours to a selected time if the AM or PM button has been clicked
  useEffect(() => {
    if (startTime != null) {
      let dateObj = new Date(startTime);
      const currentHour = dateObj.getHours();
      const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";

      // current time is in the PM and the AM button is clicked - subtract 12 hours from that time
      if (currentHour >= 12 && dayPeriod === "AM") {
        dateObj = dateObj.setHours(dateObj.getHours() - 12);
      }
      // current hour is in the AM and the PM button is clicked - add 12 hour to that time
      else if (currentHour < 12 && dayPeriod === "PM") {
        dateObj = dateObj.setHours(dateObj.getHours() + 12);
      }

      setStartTime(dateObj);
    }
  }, [alternatingButtonCheck]);

  // function that allows routing to whatever page when called
  const cancelRoute = () => {
    const path = `/appointments`;
    history.push(path);
  };

  // function that handles the date change on different calendar components
  const handleDateChange = (dateVal, type) => {
    switch (type) {
      case "date":
        setDate(dateVal);
        break;
      default:
        break;
    }
  };

  // function that handles the time change on the timepicker component
  const handleTimeChange = (dateVal) => {
    setStartTime(dateVal);
  };

  // function that sets the state of the current time period (AM or PM) after a time is selected from the picker
  const handleTimeAccept = (dateVal) => {
    const dateObj = new Date(dateVal);
    const hour = dateObj.getHours();

    if (hour >= 12) {
      setAlternatingButtonCheck(true);
    } else {
      setAlternatingButtonCheck(false);
    }
  };

  // function that sets the time picker default time to the users current time when opened
  const getInitialTime = () => {
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";

    // if the PM button is pressed before the timepicker is opened, 12 hours are added to the time
    if (dayPeriod === "PM") {
      initialDate = initialDate.setHours(initialDate.getHours() + 12);
    }

    return initialDate;
  };

  // function that checks if all of the required input fields have values associated to them
  const validateInputs = () => {
    if (confirmationNumber.length > 0 && date != null && startTime != null) {
      return true;
    }
    return false;
  };
  return (
    <form>
      <Wrapper container direction="column">
        <BreadCrumbRow container>
          <Grid item xs={12}>
            <BreadCrumbs
              currentPage="Appointment Reschedule"
              currentPath="/appointments/reschedule"
            />
          </Grid>
        </BreadCrumbRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderLarge>Reschedule Appointment Request</HeaderLarge>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Appointment Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Confirmation #"
              textFieldPlaceholder=""
              setValue={setConfirmationNumber}
              value={confirmationNumber}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DatePicker
              dateState={date}
              type="date"
              label="Date"
              dateChange={handleDateChange}
            />
            <LargeSpacer style={{ marginLeft: "1rem" }} />
            <TimePicker
              timeState={startTime}
              label="Start Time"
              onChange={handleTimeChange}
              onAccept={handleTimeAccept}
              initialFocusedDate={getInitialTime}
            />
            <SmallSpacer style={{ marginLeft: "-2rem" }} />
            <AlternatingTabButton
              tags={["AM", "PM"]}
              toggleHook={setAlternatingButtonCheck}
              checked={alternatingButtonCheck}
              style={{ marginLeft: "1rem" }}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DescriptionText>Special Requests</DescriptionText>
          </GridItem>
        </GridRow>
        <GridRow container style={{ marginTop: "1rem" }}>
          <GridItem item xs={12} container>
            <TextArea
              placeholder="Please indicate machine or technologist preference."
              value={preference}
              setValue={setPreference}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container style={{ marginTop: "1rem" }}>
          <GridItem item xs={12} container>
            <HeaderSmall>Requesting Scheduler Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Scheduler"
              textFieldPlaceholder="First"
              setValue={null}
              value="Jane Doe"
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Email for Confirmation"
              textFieldPlaceholder=""
              setValue={null}
              value="read-only@gmail.com"
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Call Back Phone #"
              textFieldPlaceholder=""
              setValue={null}
              value="111-111-1111"
            />
            <SmallSpacer />
            <TextInput
              labelText="Extension"
              textFieldPlaceholder=""
              setValue={null}
              value="123"
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem
            item
            xs={12}
            container
            direction="row"
            style={{ justifyContent: "space-between" }}
          >
            <DeleteButton>Cancel Appointment</DeleteButton>
            <CenterRow container>
              <BlueButton
                color="primary"
                variant="outlined"
                buttonType="cancel"
                onClick={() => cancelRoute()}
              >
                Cancel
              </BlueButton>
              <BlueButton
                color="primary"
                variant="contained"
                buttonType="submit"
                isDisabled={!validateInputs()}
                data-testid="reschedule-appointment-request-button"
              >
                Submit Appointment Request
              </BlueButton>
            </CenterRow>
          </GridItem>
        </GridRow>
      </Wrapper>
    </form>
  );
};

export default RescheduleAppointment;
