import React from "react";
import AppRouter from "./AppRouter";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

import { ThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";

import { muiTheme } from "../constants/theme";

/**
 * The app component, includes theme providers for both MUI and Styled Components.
 *
 * @returns {React.Element} The app router.
 */
const App = () => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ checkLoginIframe: false }}
  >
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <AppRouter />
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </ReactKeycloakProvider>
);

export default App;
