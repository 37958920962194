import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useKeycloak } from "@react-keycloak/web";
import { useLocation, Redirect } from "react-router-dom";

import { Box, Button, Container, Grid, Typography } from "@material-ui/core";

import { appointments as appointmentsRoute } from "../../constants/routes";

// Styled components
const LoginBox = styled(Box)`
  background-color: #fdfdfd;
  border-radius: 1.875rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 2.4375rem;
  padding-bottom: 2.4375rem;
`;

const BoxContainer = styled(Box)`
  background: rgb(128, 194, 131);
  background: linear-gradient(
    225deg,
    rgba(128, 194, 131, 1) 0%,
    rgba(41, 98, 255, 1) 35%,
    rgba(35, 70, 167, 1) 100%
  );
`;

const LoginHeaderText = styled(Typography)`
  margin-bottom: 0; // prevent theme and container spacing from doubling up
`;

const LoginButton = styled(Button)`
  background-color: #80c283;
  width: 15rem;
  height: 3.5rem;
  font-weight: bold;
  color: white;
  border-radius: 0.5rem;
`;

const GridContainer = styled(Grid)`
  position: relative;
  height: 100%;
`;

/**
 * The login page container.
 *
 * @param {object} props
 *
 * @param {React.Element|null} props.children
 *
 * @returns {React.Element}
 */
const PageContainer = ({ children }) => (
  <Container disableGutters maxWidth={false}>
    <BoxContainer
      height="calc(100vh - 3.75rem)"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box display="flex" height="100%" alignItems="center">
        {children}
      </Box>
    </BoxContainer>
  </Container>
);

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

/**
 * The login page component. Will redirect back to source page if the user is authenticated.
 *
 * @returns {React.Element}
 */
const LoginPage = () => {
  const location = useLocation();

  // If user was redirected from another page, store that page path
  const { from } = location.state || {
    from: { pathname: appointmentsRoute },
  };

  // Initialize Keycloak
  const { keycloak } = useKeycloak();

  // Authenticate and then redirect to Appointments
  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  // If already logged in, redirect to source page, if any (defaults to Appointments)
  if (keycloak?.authenticated) {
    return <Redirect to={from} />;
  }
  return (
    <PageContainer>
      <form>
        <LoginBox height="25rem" width="43.125rem">
          <GridContainer
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <LoginHeaderText variant="h1">Welcome</LoginHeaderText>
            </Grid>

            <Grid
              item
              xs
              container
              direction="column-reverse"
              alignContent="center"
            >
              <LoginButton
                fullWidth
                type="button"
                variant="contained"
                size="large"
                onClick={login}
                data-testid="login-button"
              >
                Secure Sign-On
              </LoginButton>
            </Grid>
          </GridContainer>
        </LoginBox>
      </form>
    </PageContainer>
  );
};

export default LoginPage;
