import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import BlueButton from "../../components/BlueButton";
import DropDown from "../../components/DropDown";
import DatePicker from "../../components/DatePicker";
import TimePicker from "../../components/TimePicker";
import CheckboxGrid from "../../components/CheckboxGrid";
import AlternatingTabButton from "../../components/AlternatingTabButton";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import RadioButton from "../../components/RadioButton";
import BreadCrumbs from "../../components/BreadCrumbs";
import AdditionButton from "../../components/AdditionButton";

// styled components
const Wrapper = styled(Grid)`
  background-color: white;
  padding-bottom: 3rem;
  min-height: calc(100vh - 7.125rem);
  overflow-x: hidden;
  padding-left: 3rem;
  padding-right: 3rem;
`;

const GridRow = styled(Grid)`
  margin-top: 1.5rem;
`;

const BreadCrumbRow = styled(Grid)`
  margin-top: 1rem;
`;

const GridItem = styled(Grid)`
  direction: row;
  justifycontent: flex-start;
  align-items: center;
`;

const HeaderLarge = styled(Typography)`
  text-align: center;
  color: #333333;
  font-size: 1.5rem;
`;

const HeaderSmall = styled(Typography)`
  text-align: center;
  color: #5c5c5c;
  font-size: 1.1rem;
`;

const DescriptionText = styled(Typography)`
  font-size: 0.75rem;
  color: #5c5c5c;
  text-align: left;
  width: fit-content;
`;

const LargeSpacer = styled(Box)`
  width: 6rem;
  height: 1px;
  background-color: transparent;
`;

const SmallSpacer = styled(Box)`
  width: 2.5rem;
  height: 1px;
  background-color: transparent;
`;

const Divider = styled(Box)`
  position: relative;
  width: 100%;
  height: 0.1rem;
  background-color: rgba(128, 194, 131, 0.88);
  margin-top: 2rem;
`;

/**
 * The Request New Appointment page and all corresponding UI elements.
 *
 * @private
 *
 * @returns {React.Element}
 *
 */

const NewAppointmentPage = () => {
  // most of these are dummy state values for now
  const [preference, setPreference] = useState("");
  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [insuranceProvider, setInsuranceProvider] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [memberID, setMemberID] = useState("");
  const [procedureLength, setProcedureLength] = useState("");

  const PROCEDURE_TYPES = [
    { value: "Procedure 1", id: "p1" },
    { value: "Procedure 2", id: "p2" },
    { value: "Procedure 3", id: "p3" },
    { value: "Procedure 4", id: "p4" },
    { value: "Procedure 5", id: "p5" },
    { value: "Procedure 6", id: "p6" },
  ];
  const [selectedProcedures, setSelectedProcedures] = useState([]);

  const [visitDate, setVisitDate] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [alternatingButtonCheck, setAlternatingButtonCheck] = useState(false);

  const FACILITIES = ["Facility 1", "Facility 2", "Facility 3"];
  const [currentFacility, setCurrentFacility] = useState("");

  const PHYSICIAN = ["Physician 1", "Physician 2", "Physician 3"];
  const [currentPhysician, setCurrentPhysician] = useState("");

  const SERVICE_LOCATION = ["Location 1", "Location 2", "Location 3"];
  const [currentServiceLocation, setCurrentServiceLocation] = useState("");

  const [gender, setGender] = useState(null);

  const BILATERAL_PROC = [
    { value: "Bilateral Procedure will be performed", id: "b1" },
  ];
  const [selectedBilateralProc, setSelectedBilaterialProc] = useState([]);

  const history = useHistory();

  // function that allows routing to whatever page when called
  const navigateToHome = () => {
    const path = `/appointments`;
    history.push(path);
  };

  // useEffect fired only if the dayPeriod state changes - this is to add or subtract 12 hours to a selected time if the AM or PM button has been clicked
  useEffect(() => {
    if (startTime != null) {
      let dateObj = new Date(startTime);
      const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";
      const currentHour = dateObj.getHours();

      // current time is in the PM and the AM button is clicked - subtract 12 hours from that time
      if (currentHour >= 12 && dayPeriod === "AM") {
        dateObj = dateObj.setHours(dateObj.getHours() - 12);
      }
      // current hour is in the AM and the PM button is clicked - add 12 hour to that time
      else if (currentHour < 12 && dayPeriod === "PM") {
        dateObj = dateObj.setHours(dateObj.getHours() + 12);
      }

      setStartTime(dateObj);
    }
  }, [alternatingButtonCheck]);

  // function that handles the click events on the different dropdown components - uses switch/case to set the state properly for each one
  const handleChange = (event, type) => {
    switch (type) {
      case "facility":
        setCurrentFacility(event.target.value);
        break;
      case "serviceLocation":
        setCurrentServiceLocation(event.target.value);
        break;
      case "physician":
        setCurrentPhysician(event.target.value);
        break;
      default:
        break;
    }
  };

  // function that handles the date change on different calendar components
  const handleDateChange = (date, type) => {
    switch (type) {
      case "visit":
        setVisitDate(date);
        break;
      case "DOB":
        setDOB(date);
        break;
      default:
        break;
    }
  };

  // function that handles the time change on the timepicker component
  const handleTimeChange = (date) => {
    setStartTime(date);
  };

  // function that handles the selection/deselection of checkboxes
  const handleCheckboxChange = (val, type) => {
    // temp array that is assigned to the state value of a certain type of checkbox
    const stateArray =
      type === "grid" ? [...selectedProcedures] : [...selectedBilateralProc];

    // if the stateArray includes the checked value it is removed from the array
    if (stateArray.includes(val)) {
      const index = stateArray.indexOf(val);
      if (index > -1) {
        stateArray.splice(index, 1);
      }
    }
    // if not the value is added to the array
    else {
      stateArray.push(val);
    }

    // updating the correct state
    type === "grid"
      ? setSelectedProcedures(stateArray)
      : setSelectedBilaterialProc(stateArray);
  };

  // function that sets the time picker default time to the users current time when opened
  const getInitialTime = () => {
    let initialDate = new Date();
    initialDate.setHours(0, 0, 0, 0);
    const dayPeriod = !alternatingButtonCheck ? "AM" : "PM";

    // if the PM button is pressed before the timepicker is opened, 12 hours are added to the time
    if (dayPeriod === "PM") {
      initialDate = initialDate.setHours(initialDate.getHours() + 12);
    }

    return initialDate;
  };

  // function that sets the state of the current time period (AM or PM) after a time is selected from the picker
  const handleTimeAccept = (date) => {
    const dateObj = new Date(date);
    const hour = dateObj.getHours();

    if (hour >= 12) {
      setAlternatingButtonCheck(true);
    } else {
      setAlternatingButtonCheck(false);
    }
  };

  // function that checks if all of the required input fields have values associated to them
  const validateInputs = () => {
    if (
      currentPhysician.length > 0 &&
      currentFacility.length > 0 &&
      currentServiceLocation.length > 0 &&
      visitDate != null &&
      patientFirstName.length > 0 &&
      patientLastName.length > 0 &&
      DOB != null &&
      insuranceProvider.length > 0 &&
      groupNumber.length > 0 &&
      memberID.length > 0 &&
      gender != null &&
      selectedProcedures.length > 0 &&
      startTime != null &&
      procedureLength.length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <form>
      <Wrapper container direction="column">
        <BreadCrumbRow container>
          <Grid item xs={12}>
            <BreadCrumbs
              currentPage="Appointment Request"
              currentPath="/appointments/new"
            />
          </Grid>
        </BreadCrumbRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderLarge>Appointment Request</HeaderLarge>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Visit Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DropDown
              menuItems={PHYSICIAN}
              type="physician"
              currentValue={currentPhysician}
              label="Physician"
              onChange={handleChange}
            />
            <SmallSpacer />
            <AdditionButton variant="text">Add New Physician</AdditionButton>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DropDown
              menuItems={FACILITIES}
              type="facility"
              currentValue={currentFacility}
              label="Facility"
              onChange={handleChange}
            />
            <LargeSpacer />
            <DropDown
              menuItems={SERVICE_LOCATION}
              type="serviceLocation"
              currentValue={currentServiceLocation}
              label="Service Location"
              onChange={handleChange}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DatePicker
              dateState={visitDate}
              type="visit"
              label="Date"
              dateChange={handleDateChange}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Patient Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Patient"
              textFieldPlaceholder="First"
              setValue={setPatientFirstName}
              value={patientFirstName}
            />
            <SmallSpacer />
            <TextInput
              labelText={null}
              textFieldPlaceholder="Last"
              setValue={setPatientLastName}
              value={patientLastName}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DatePicker
              dateState={DOB}
              type="DOB"
              label="Date of Birth"
              dateChange={handleDateChange}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Insurance Provider"
              textFieldPlaceholder=""
              setValue={setInsuranceProvider}
              value={insuranceProvider}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Group #"
              textFieldPlaceholder=""
              setValue={setGroupNumber}
              value={groupNumber}
            />
            <LargeSpacer />
            <TextInput
              labelText="Member ID"
              textFieldPlaceholder=""
              setValue={setMemberID}
              value={memberID}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <RadioButton
              radioPlaceholder="Male"
              labelText="Sex"
              clickFunction={setGender}
              value={gender}
            />
            <SmallSpacer />
            <RadioButton
              radioPlaceholder="Female"
              labelText={null}
              clickFunction={setGender}
              value={gender}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Procedure Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <CheckboxGrid
              label="Procedure"
              type="grid"
              checkboxValues={PROCEDURE_TYPES}
              selectedValues={selectedProcedures}
              toggleCheckbox={handleCheckboxChange}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <CheckboxGrid
              label={null}
              type="single"
              checkboxValues={BILATERAL_PROC}
              selectedValues={selectedBilateralProc}
              toggleCheckbox={handleCheckboxChange}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TimePicker
              timeState={startTime}
              label="Start Time"
              onChange={handleTimeChange}
              onAccept={handleTimeAccept}
              initialFocusedDate={getInitialTime}
            />
            <SmallSpacer style={{ marginLeft: "-1rem" }} />
            <AlternatingTabButton
              tags={["AM", "PM"]}
              toggleHook={setAlternatingButtonCheck}
              checked={alternatingButtonCheck}
              style={{ marginLeft: "1rem" }}
            />
            <LargeSpacer style={{ marginLeft: "-2rem" }} />
            <TextInput
              labelText="Length of Procedure"
              textFieldPlaceholder=""
              setValue={setProcedureLength}
              value={procedureLength}
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <DescriptionText>Special Requests</DescriptionText>
          </GridItem>
        </GridRow>
        <GridRow container style={{ marginTop: "1rem" }}>
          <GridItem item xs={12} container>
            <TextArea
              placeholder="Please indicate machine or technologist preference."
              value={preference}
              setValue={setPreference}
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem item xs={12} container>
            <HeaderSmall>Requesting Scheduler Information</HeaderSmall>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container style={{ marginTop: "-1rem" }}>
            <DescriptionText>
              If any information looks incorrect, please contact support.
            </DescriptionText>
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Scheduler"
              textFieldPlaceholder="First"
              setValue={null}
              value="Jane Doe"
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Email for Confirmation"
              textFieldPlaceholder=""
              setValue={null}
              value="read-only@gmail.com"
            />
          </GridItem>
        </GridRow>
        <GridRow container>
          <GridItem item xs={12} container>
            <TextInput
              labelText="Call Back Phone #"
              textFieldPlaceholder=""
              setValue={null}
              value="111-111-1111"
            />
            <SmallSpacer />
            <TextInput
              labelText="Extension"
              textFieldPlaceholder=""
              setValue={null}
              value="123"
            />
          </GridItem>
        </GridRow>
        <Divider />
        <GridRow container>
          <GridItem item xs={12} container direction="row-reverse">
            <BlueButton
              color="primary"
              variant="contained"
              buttonType="submit"
              isDisabled={!validateInputs()}
              data-testid="submit-appointment-request-button"
            >
              Submit Appointment Request
            </BlueButton>
            <BlueButton
              color="primary"
              variant="outlined"
              buttonType="cancel"
              onClick={navigateToHome}
            >
              Cancel
            </BlueButton>
          </GridItem>
        </GridRow>
      </Wrapper>
    </form>
  );
};

export default NewAppointmentPage;
