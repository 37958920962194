import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Button, Typography, Box } from "@material-ui/core";
import rescheduleSVG from "../assets/svgs/reschedule.svg";
import cancelSVG from "../assets/svgs/cancel.svg";
import newSVG from "../assets/svgs/new.svg";

const StyledButton = styled(Button)`
  background-color: white;
  height: 11em;
  width: 22em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-top: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;

  &:hover {
    background-color: #cfcfcf;
  }
`;

const ButtonText = styled(Typography)`
  font-weight: 500;
  text-align: center;
  color: #2962ff;
  font-size: 1.3em;
  letter-spacing: 0.5px;
  margin-top: 8px;
`;

const FlexCol = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

/**
 * Menu Button Component - Present within dashboard
 *
 * @param {object} props
 * @param props.children
 * @param props.type
 * @param props.path
 * @returns {React.Element}
 */
const MenuButton = ({ children, type, path }) => {
  const history = useHistory();

  // added function for route changing because the routerLink component broke the flexbox styling for some reason
  const routeChange = (urlPath) => {
    history.push(urlPath);
  };

  const IconSelection = (givenType) => {
    switch (givenType) {
      case "New":
        return <img src={newSVG} alt="new appointment" />;
      case "Reschedule":
        return <img src={rescheduleSVG} alt="reschedule appointment" />;
      case "Cancel":
        return <img src={cancelSVG} alt="cancel appointment" />;
      default:
        return null;
    }
  };

  return (
    <StyledButton onClick={() => routeChange(path)}>
      <FlexCol>
        {IconSelection(type)}
        <ButtonText>{children}</ButtonText>
      </FlexCol>
    </StyledButton>
  );
};

MenuButton.propTypes = {
  children: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default MenuButton;
