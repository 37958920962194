import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

// styled components
const StyledDeleteButton = styled(Button)`
  height: 2.5rem;
  min-width: 12rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-weight: bold;
  text-align: center;
  font-size: 1em;
  background-color: white;
  border: 2px solid #d50000;
  color: #d50000;

  &:hover {
    background-color: #d50000;
    color: white;
  }
`;

const DeleteIcon = styled(DeleteOutlineOutlinedIcon)`
  font-size: 1.25em;
  text-align: center;
`;

/**
 * Delete Button Component
 *
 * @param {object} props
 * @param props.children
 * @returns {React.Element}
 */
const DeleteButton = ({ children }) => (
  <StyledDeleteButton startIcon={<DeleteIcon />}>{children}</StyledDeleteButton>
);

DeleteButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DeleteButton;
