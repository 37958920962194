import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import colors from "../constants/colors";

// styled components
const StyledFieldLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    flex-shrink: 0;
    color: ${colors.grey.mediumMedium};
    width: ${(props) => props.labelwidth};
    text-align: left;
    font-size: 0.8rem;
  }
  .MuiFormControlLabel-root {
    justify-content: flex-end;
    width: 100%;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

/**
 * A generic FieldLabel component, places the label to the left to allow vertical alignment in forms
 * TODO: Eliminate the labelType prop, intead just accept the desired label with with an appropriately named prop
 *
 * @param {object} props
 * @param props.label
 * @param props.labelPlacement
 * @param props.control
 * @param props.style
 * @param props.labelwidth
 * @returns {React.Element}
 */
const FieldLabel = ({ label, labelPlacement, control, style, labelwidth }) => (
  <StyledFieldLabel
    control={control}
    label={label}
    style={style}
    labelPlacement={labelPlacement}
    labelwidth={labelwidth}
  />
);

FieldLabel.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  control: PropTypes.element.isRequired,
  labelwidth: PropTypes.string, // was getting a DOM error for camel case spelling
  style: PropTypes.shape(),
};

FieldLabel.defaultProps = {
  label: null,
  labelPlacement: "left",
  style: { marginLeft: 0 },
  labelwidth: "10rem",
};

export default FieldLabel;
