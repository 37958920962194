import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../constants/colors";
import { TextField } from "@material-ui/core";

// styled components
const CustomTextArea = styled(TextField)`
  background-color: ${colors.white};
  width: 65rem;
  border-radius: 8;
  border-color: ${colors.grey.light};

  .MuiInput-root.Mui-focused {
    border-width: 1px;
    height: fit-content;
  }

  .MuiInput-root {
    height: fit-content;
  }
`;

/**
 * Custom Text Area Component
 *
 * @param {object} props
 * @param props.placeholder
 * @param props.setValue
 * @param props.value
 * @returns {React.Element}
 */
const TextArea = ({ placeholder, setValue, value }) => (
  <CustomTextArea
    multiline
    rows={5}
    rowsMax={Infinity}
    placeholder={placeholder}
    InputProps={{ disableUnderline: true }}
    onChange={(e) => setValue(e.target.value)}
    value={value}
  />
);

TextArea.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default TextArea;
